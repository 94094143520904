<template>
  <div id="app">
      <RouterView />
  </div>
</template>
<script>

export default {
  name: 'App',
}

</script>

<style>

body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Open Sans', sans-serif;
}

</style>
