import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'Main',
            component: () => import('@/components/layouts/MainLayout.vue'),
            children: [
                {
                    path: '/',
                    name: 'Main',
                    component: () => import('@/components/pages/MainPage.vue'),
                }
            ]
        },
        {
            path: '/software',
            name: 'SoftwareLayout',
            component: () => import(`@/components/layouts/PageLayout.vue`),
            children: [
                {
                    path: '/software/:id',
                    name: 'SoftwarePage',
                    component: () => import(`@/components/pages/SoftwarePage.vue`)
                }
            ]
        }
    ],
})

export default router;
